@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap);
/* index.css */

:root {
  --border-radius: 8px;
  --button-font-size: 1rem;
  --color-accent: #cccccc;
  --color-background: #333333;
  --color-panel-bg: #fff;
  --color-primary: #ffffff;
  --color-secondary: #000000;
  --container-max-width: 1200px;
  --font-family-primary: 'Raleway', sans-serif;
  --font-lg: 3rem;
  --font-md: 2rem;
  --font-sm: 1rem;
  --font-weight-bold: bold;
  --margin-md: 20px;
  --panel-max-width: 600px;
  --panel-padding: 20px;
}

/* GLOBAL STYLES */
body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family-primary);
  background: var(--color-background) url(/static/media/usa-topgraphical.a6d3a2a2.svg) center center /
    cover no-repeat;
  color: var(--color-primary);
  line-height: 1.6;
}

a {
  color: var(--color-accent);
  text-decoration: none;
}

button {
  background-color: var(--color-secondary);
  color: var(--color-primary);
  border: none;
  padding: var(--margin-md);
  border-radius: var(--border-radius);
  cursor: pointer;
  font-size: var(--button-font-size);
}

button:hover {
  background-color: var(--color-accent);
}

input,
select,
textarea {
  padding: var(--margin-md);
  border-radius: var(--border-radius);
  border: 2px solid var(--color-accent);
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
  border-color: var(--color-primary);
}

/* FLEXBOX UTILITIES */
.flex,
.flex-row,
.flex-column,
.align-center,
.space-between,
.space-around {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.container {
  padding: var(--margin-md);
  max-width: var(--container-max-width);
  margin: auto;
}

.align-center {
  align-items: center;
  justify-content: center;
}

.space-between {
  justify-content: space-between;
  grid-gap: var(--margin-md);
  gap: var(--margin-md);
}

.space-around {
  justify-content: space-around;
  grid-gap: var(--margin-md);
  gap: var(--margin-md);
}

.height-100 {
  height: 100%;
  flex-grow: 1;
}

.width-100 {
  width: 100%;
}

.text-center {
  text-align: center;
}

/* TYPOGRAPHY UTILITIES */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-primary);
}

h1 {
  font-size: var(--font-lg);
}

h2 {
  font-size: var(--font-md);
}

/* GLOBAL CLASSES */


/* RESPONSIVE DESIGN ADJUSTMENTS */
@media (max-width: 768px) {
  .container {
    padding: 0 var(--margin-md);
  }
}

.app {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
  position: relative;
  text-align: center;
  min-width: 100vw;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .app {
    height: auto;
    flex-direction: column;
  }
}

:root {
  --footer-text-align: center;
  --footer-nav-list-style: none;
  --footer-nav-list-item-display: inline;
  --footer-nav-list-item-margin-right: 12px;
  --footer-info-margin-top: 24px;
}

.app-footer {
  text-align: var(--footer-text-align);
}

.footer-nav ul {
  list-style: var(--footer-nav-list-style);
  padding: 0;
}

.footer-nav ul li {
  display: var(--footer-nav-list-item-display);
  margin-right: var(--footer-nav-list-item-margin-right);
}

.footer-info {
  margin-top: var(--footer-info-margin-top);
  text-align: var(--footer-text-align);
}

:root {
  --header-padding: 24px;
  --nav-margin-right: 16px;
  --font-size-base: 10px;
  --font-size-scalable: 1.25vmin;
  --color-nav-link: white;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  padding: var(--header-padding);
  box-sizing: border-box;
  font-size: calc(var(--font-size-base) + var(--font-size-scalable));
  z-index: 2;
  width: 100%;
}

.app-logo-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.app-logo {
  height: 100%;
  width: auto;
}

.app-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
}

.app-nav li {
  margin-right: var(--nav-margin-right);
}

.app-nav a {
  text-decoration: none;
  color: var(--color-nav-link);
}

/* Small devices (phones) */
@media (max-width: 320px) {
  .app-header {
    padding: var(--header-padding) 10px; /* Smaller horizontal padding */
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .app-logo-container {
    margin-bottom: var(--header-padding);
  }

  .app-nav ul {
    justify-content: center;
  }

  .app-nav li {
    margin-right: 8px; /* Smaller margin between nav items */
  }
}

/* Medium devices (tablets) */
@media (min-width: 321px) and (max-width: 768px) {
  .app-header {
    padding: var(--header-padding) 20px; /* Medium horizontal padding */
  }

  .app-logo-container {
    margin-bottom: var(--header-padding);
  }

  .app-nav ul {
    justify-content: center;
  }

  .app-nav li {
    margin-right: 12px; /* Medium margin between nav items */
  }
}

/* Large devices (desktops) */
@media (min-width: 769px) and (max-width: 1440px) {
  .app-header {
    padding: var(--header-padding); /* Default padding */
  }

  .app-logo-container {
    /* No changes; default styles apply */
  }

  .app-nav ul {
    justify-content: flex-end; /* Default alignment */
  }

  .app-nav li {
    margin-right: var(--nav-margin-right); /* Default margin */
  }
}


:root {
  --color-panel-bg: #0F0F0F;
  --border-radius: 4px;
  --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  --panel-padding: 16px;
  --panel-max-width: 600px;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  background-color: var(--color-panel-bg);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: var(--panel-padding);
  max-width: var(--panel-max-width);
  width: 100%;
  text-align: center;
}

.panel-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.panel-content {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  flex: 1 1;
  padding: 24px;
  width: 100%;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  margin-bottom: 1rem;
}

.panel-container {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
}

@media (max-width: 768px) {
  .panel-container {
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
  }
}



/* services.css */

/* Variables */
:root {
  --margin-md: 20px;
  --margin-sm: 10px;
  --padding-md: 20px;
  --price-color: #fff;
  --service-bg-color: #fff;
  --service-border-radius: 8px;
  --service-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  --service-max-width: 300px;
  --service-text-color: #666;
}

.services {
  align-items: top;
  padding: var(--padding-md);
  text-align: center;
}

.service-tier {
  display: flex;
  flex-direction: column;
  border-radius: var(--service-border-radius);
  box-shadow: var(--service-box-shadow);
  padding: var(--padding-md);
  margin: var(--margin-sm);
  max-width: var(--service-max-width);
  width: 100%;
}

.services > h1 {
  font-size: var(--font-lg);
}

.service-tier h2 {
  font-size: var(--font-md);
  margin-bottom: var(--margin-sm);
}

.service-tier p {
  font-size: var(--service-text-font-size);
  color: var(--service-text-color);
}

.service-tier ul {
  list-style: disc;
  margin-left: var(--margin-md);
  padding-left: var(--padding-md);
}

.price {
  font-size: var(--font-lg);
  font-weight: var(--font-weight-bold);
  margin-top: var(--margin-sm);
  color: var(--price-color);
}

/* Media query for mobile layout */
@media (max-width: 768px) {
  .services {
    flex-direction: column;
    overflow-x: auto;
  }

  .service-tier {
    flex: 0 0 auto;
    margin-right: var(--margin-sm);
  }

  .service-tier:last-child {
    margin-right: 0;
  }
}

/* Media query for desktop layout */
@media (min-width: 768px) {
  #service-tiers {
    flex-direction: row;
    /* justify-content: center; */
    /* flex-wrap: wrap; */
  }

  .service-tier {
    flex: 1 1;
    flex-basis: calc(33.33% - var(--margin-md));
    margin: var(--margin-md);
    align-content: top;
    align-items: center;
    justify-content: space-between;
  }
}


