:root {
  --footer-text-align: center;
  --footer-nav-list-style: none;
  --footer-nav-list-item-display: inline;
  --footer-nav-list-item-margin-right: 12px;
  --footer-info-margin-top: 24px;
}

.app-footer {
  text-align: var(--footer-text-align);
}

.footer-nav ul {
  list-style: var(--footer-nav-list-style);
  padding: 0;
}

.footer-nav ul li {
  display: var(--footer-nav-list-item-display);
  margin-right: var(--footer-nav-list-item-margin-right);
}

.footer-info {
  margin-top: var(--footer-info-margin-top);
  text-align: var(--footer-text-align);
}
