:root {
  --color-panel-bg: #0F0F0F;
  --border-radius: 4px;
  --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  --panel-padding: 16px;
  --panel-max-width: 600px;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  background-color: var(--color-panel-bg);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: var(--panel-padding);
  max-width: var(--panel-max-width);
  width: 100%;
  text-align: center;
}

.panel-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.panel-content {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  flex: 1;
  padding: 24px;
  width: 100%;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  margin-bottom: 1rem;
}

.panel-container {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
}

@media (max-width: 768px) {
  .panel-container {
    flex-direction: column;
    gap: 16px;
  }
}
