.app {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
  position: relative;
  text-align: center;
  min-width: 100vw;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .app {
    height: auto;
    flex-direction: column;
  }
}
