/* index.css */
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

:root {
  --border-radius: 8px;
  --button-font-size: 1rem;
  --color-accent: #cccccc;
  --color-background: #333333;
  --color-panel-bg: #fff;
  --color-primary: #ffffff;
  --color-secondary: #000000;
  --container-max-width: 1200px;
  --font-family-primary: 'Raleway', sans-serif;
  --font-lg: 3rem;
  --font-md: 2rem;
  --font-sm: 1rem;
  --font-weight-bold: bold;
  --margin-md: 20px;
  --panel-max-width: 600px;
  --panel-padding: 20px;
}

/* GLOBAL STYLES */
body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family-primary);
  background: var(--color-background) url('usa-topgraphical.svg') center center /
    cover no-repeat;
  color: var(--color-primary);
  line-height: 1.6;
}

a {
  color: var(--color-accent);
  text-decoration: none;
}

button {
  background-color: var(--color-secondary);
  color: var(--color-primary);
  border: none;
  padding: var(--margin-md);
  border-radius: var(--border-radius);
  cursor: pointer;
  font-size: var(--button-font-size);
}

button:hover {
  background-color: var(--color-accent);
}

input,
select,
textarea {
  padding: var(--margin-md);
  border-radius: var(--border-radius);
  border: 2px solid var(--color-accent);
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
  border-color: var(--color-primary);
}

/* FLEXBOX UTILITIES */
.flex,
.flex-row,
.flex-column,
.align-center,
.space-between,
.space-around {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.container {
  padding: var(--margin-md);
  max-width: var(--container-max-width);
  margin: auto;
}

.align-center {
  align-items: center;
  justify-content: center;
}

.space-between {
  justify-content: space-between;
  gap: var(--margin-md);
}

.space-around {
  justify-content: space-around;
  gap: var(--margin-md);
}

.height-100 {
  height: 100%;
  flex-grow: 1;
}

.width-100 {
  width: 100%;
}

.text-center {
  text-align: center;
}

/* TYPOGRAPHY UTILITIES */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-primary);
}

h1 {
  font-size: var(--font-lg);
}

h2 {
  font-size: var(--font-md);
}

/* GLOBAL CLASSES */


/* RESPONSIVE DESIGN ADJUSTMENTS */
@media (max-width: 768px) {
  .container {
    padding: 0 var(--margin-md);
  }
}
