:root {
  --header-padding: 24px;
  --nav-margin-right: 16px;
  --font-size-base: 10px;
  --font-size-scalable: 1.25vmin;
  --color-nav-link: white;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  padding: var(--header-padding);
  box-sizing: border-box;
  font-size: calc(var(--font-size-base) + var(--font-size-scalable));
  z-index: 2;
  width: 100%;
}

.app-logo-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.app-logo {
  height: 100%;
  width: auto;
}

.app-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
}

.app-nav li {
  margin-right: var(--nav-margin-right);
}

.app-nav a {
  text-decoration: none;
  color: var(--color-nav-link);
}

/* Small devices (phones) */
@media (max-width: 320px) {
  .app-header {
    padding: var(--header-padding) 10px; /* Smaller horizontal padding */
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .app-logo-container {
    margin-bottom: var(--header-padding);
  }

  .app-nav ul {
    justify-content: center;
  }

  .app-nav li {
    margin-right: 8px; /* Smaller margin between nav items */
  }
}

/* Medium devices (tablets) */
@media (min-width: 321px) and (max-width: 768px) {
  .app-header {
    padding: var(--header-padding) 20px; /* Medium horizontal padding */
  }

  .app-logo-container {
    margin-bottom: var(--header-padding);
  }

  .app-nav ul {
    justify-content: center;
  }

  .app-nav li {
    margin-right: 12px; /* Medium margin between nav items */
  }
}

/* Large devices (desktops) */
@media (min-width: 769px) and (max-width: 1440px) {
  .app-header {
    padding: var(--header-padding); /* Default padding */
  }

  .app-logo-container {
    /* No changes; default styles apply */
  }

  .app-nav ul {
    justify-content: flex-end; /* Default alignment */
  }

  .app-nav li {
    margin-right: var(--nav-margin-right); /* Default margin */
  }
}
