/* services.css */

/* Variables */
:root {
  --margin-md: 20px;
  --margin-sm: 10px;
  --padding-md: 20px;
  --price-color: #fff;
  --service-bg-color: #fff;
  --service-border-radius: 8px;
  --service-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  --service-max-width: 300px;
  --service-text-color: #666;
}

.services {
  align-items: top;
  padding: var(--padding-md);
  text-align: center;
}

.service-tier {
  display: flex;
  flex-direction: column;
  border-radius: var(--service-border-radius);
  box-shadow: var(--service-box-shadow);
  padding: var(--padding-md);
  margin: var(--margin-sm);
  max-width: var(--service-max-width);
  width: 100%;
}

.services > h1 {
  font-size: var(--font-lg);
}

.service-tier h2 {
  font-size: var(--font-md);
  margin-bottom: var(--margin-sm);
}

.service-tier p {
  font-size: var(--service-text-font-size);
  color: var(--service-text-color);
}

.service-tier ul {
  list-style: disc;
  margin-left: var(--margin-md);
  padding-left: var(--padding-md);
}

.price {
  font-size: var(--font-lg);
  font-weight: var(--font-weight-bold);
  margin-top: var(--margin-sm);
  color: var(--price-color);
}

/* Media query for mobile layout */
@media (max-width: 768px) {
  .services {
    flex-direction: column;
    overflow-x: auto;
  }

  .service-tier {
    flex: 0 0 auto;
    margin-right: var(--margin-sm);
  }

  .service-tier:last-child {
    margin-right: 0;
  }
}

/* Media query for desktop layout */
@media (min-width: 768px) {
  #service-tiers {
    flex-direction: row;
    /* justify-content: center; */
    /* flex-wrap: wrap; */
  }

  .service-tier {
    flex: 1 1;
    flex-basis: calc(33.33% - var(--margin-md));
    margin: var(--margin-md);
    align-content: top;
    align-items: center;
    justify-content: space-between;
  }
}
